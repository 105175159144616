<template>

    <section class="section container columns is-centered  pt-4 ">
        <div class="column  is-half-desktop  ">
            <div class="field  columns has-text-centered">
                <div v-show="!isMobile"  class="column  has-text-left mr-6">
                    <label class="label">Enviar email ultimo dia del mes.</label>
                </div>
                <div  v-show="!isMobile" class="control column  mt-2 mr-6">
                    <label class="checkbox-label">

                            <input v-if="!habilitadoMes" type="checkbox" @click="habilitarTerminarMes">
                            <input v-else type="checkbox" checked @click="habilitarTerminarMes">

                        <span class="mt-2 checkbox-custom"></span>

                    </label>
                </div>

                <div v-show="isMobile"  class="column">
                    <label class="label">Enviar email ultimo dia del mes.</label>
                </div>
                <div  v-show="isMobile" class="control column">
                    <label class="checkbox-label">

                            <input v-if="!habilitadoMes" type="checkbox" @click="habilitarTerminarMes">
                            <input v-else type="checkbox" checked @click="habilitarTerminarMes">
                        <span class="mt-2 checkbox-custom"></span>

                    </label>
                </div>

            </div>


            <div class="field  columns has-text-centered">
                <div v-show="!isMobile"  class="column has-text-left mr-6">
                    <label class="label">Enviar email al terminar anuncio</label>
                </div>
                <div  v-show="!isMobile" class="control column   mt-2 mr-6">
                     <label class="checkbox-label">
                    <!-- <label > -->

                            <input v-if="!habilitadoAnuncio" type="checkbox" @click="habilitarTerminarAnuncio">
                            <input v-else type="checkbox" checked @click="habilitarTerminarAnuncio">

                        <span class="mt-2 checkbox-custom"></span>

                    </label>
                </div>

                <div v-show="isMobile"  class="column">
                    <label class="label">Enviar email al terminar anuncio</label>
                </div>
                <div  v-show="isMobile" class="control column">
                    <label class="checkbox-label">

                        <input v-if="!habilitadoAnuncio" type="checkbox" @click="habilitarTerminarAnuncio">
                        <input v-else type="checkbox" checked @click="habilitarTerminarAnuncio">
                        <span class="mt-2 checkbox-custom"></span>

                    </label>
                </div>

            </div>

            <div v-show="!isMobile" class="field columns has-text-centered">
                <div class="column  is-flex-grow-0 has-text-left ">
                      <button class="button   " style=" background-color: #DD2923 ; color:white;" @click="volver">
                            Cancelar
                        </button>
                </div>
                <div class="control column   has-text-left ">
                        <button v-show="!cargando" class="button is-success " style="width: 17.1rem;" @click="validar">
                             Guardar configuracion de email
                        </button>
                        <button v-show="cargando" class="button is-success is-loading" style="width: 17.1rem;"></button>
                </div>
            </div>
                <div v-show="isMobile" class="field columns has-text-centered">
                <div class="control column   is-flex-grow-0 ">
                    <button v-show="!cargando" class="button  is-success " style="width: 17.1rem;" @click="validar">
                            Guardar configuracion de email
                    </button>
                    <button v-show="cargando" class="button is-success is-loading" style="width: 17.1rem;"></button>
                </div>
                <div class="column ">
                      <button class="button mr-2  " style=" background-color: #DD2923 ; color:white;" @click="volver">
                            Cancelar
                        </button>
                </div>

            </div>



    </div>
  </section>



</template>

<script>
import { GraphQLClient } from 'graphql-request'
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import store from '@/store'
import { inject } from '@vue/runtime-core'
import {queryMe} from '@/composables/queryMe.js'

export default {

 name: 'IntegracionEnvioCorreo',


setup(){

        const isMobile = inject('isMobile')
        const error = ref(false)
        const message_error = ref([
            {clave_error: '', host_error: '', puerto_error: ''}
        ])
        const clave = ref('')
        const host = ref('')
        const puerto = ref('')
        const limiteCantidad = ref('')
        const limiteTiempo = ref('')
        const copia = ref('')
        const contacto = ref('')
        const habilitado= ref(false)
        const habilitadoAnuncio= ref(false)
        const habilitadoMes= ref(false)
        const tipo= ref('email')
        const config = ref('')
        const editar= ref(false)
        const passwordFieldType = ref('password');
        const id= ref(null)
        const router = useRouter()
        const endpoint = store.state.url_backend
        const cargando = ref(false)

        const habilitarTerminarAnuncio = () => {
            habilitadoAnuncio.value = !habilitadoAnuncio.value
        }
        const habilitarTerminarMes = () => {
            habilitadoMes.value = !habilitadoMes.value
        }

        // const switchVisibility = () => {
        //     passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
        //     // console.log(passwordFieldType)
        // }

        const validar = async () => {
            cargando.value = true
            let habilitado = await queryMe()
            if (habilitado) {
                validando()
            } else {
                router.push({name: 'Login'})
            }
        }

        const validando = () => {
            error.value = false
            // message_error.value.clave_error = ''
            // message_error.value.host_error = ''
            // message_error.value.puerto_error = ''

            // if (clave.value == '') {
            //     error.value = true
            //     message_error.value.clave_error = 'El campo clave no puede estar vacio'
            // }
            // if (host.value ==  '') {
            //     error.value = true
            //     message_error.value.host_error = 'El campo host no puede estar vacio'
            // }
            // if (puerto.value ==  '') {
            //     error.value = true
            //     message_error.value.puerto_error = 'El campo puerto no puede estar vacio'
            // }

            if (error.value) {
                cargando.value = false
            } else {
                if (!editar.value) {
                    crearIntegracionCorreo()
                } else {
                    editarConfiguracionCorreo()
                }
            }
        }

        const traerCorreo = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            client.rawRequest(/* GraphQL */ `
            query{
                configsistemasxtipo(tipo: "email"){
                    id
                    tipo
                    config
                }
            }`,
            {
                // variables
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then(data => {
                // console.log(data)
                let email = data.data.configsistemasxtipo
                if (email.length == 0) {
                    editar.value = false
                } else {
                    id.value = email[0].id
                    editar.value = true
                    let config = JSON.parse(email[0].config)

                    if (config.finAnuncio == 'true') {
                        habilitadoAnuncio.value = true
                    } else {
                        habilitadoAnuncio.value = false
                    }

                    if (config.finMes == 'true') {
                        habilitadoMes.value = true
                    } else {
                        habilitadoMes.value = false
                    }

                }
            })
        }

        const crearIntegracionCorreo = () => {
            // config.value = `{\"habilitar\":\"${habilitado.value}\", \"clave\":\"${clave.value}\", \"contacto\":\"${contacto.value}\",\"copia\":\"${copia.value}\", \"host\":\"${host.value}\", \"limite_de_tiempo\":\"${limiteTiempo.value}\", \"limite_de_cantidad\":\"${limiteCantidad.value}\", \"puerto\":\"${puerto.value}\"}`

            config.value = `{\"finMes\":\"${habilitadoMes.value}\",\"finAnuncio\":\"${habilitadoAnuncio.value}\"}`
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            client.rawRequest(/* GraphQL */ `
            mutation($tipo:String!, $config:String!){
               creaConfigsistema(input: {
                        tipo: $tipo,
                        config: $config,
                    }){
                        id,
                        tipo,
                        config
                    }
            }`,
            {
                tipo: tipo.value,
                config: config.value,
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                router.push({name: 'Configuraciones'})
                let accion = "nuevaintegracion"
                store.commit('verificar_carga',accion)

            }).catch(error => {
            //   console.log(error.response);

            })
        }
        const editarConfiguracionCorreo = () => {
            // config.value = `{\"habilitar\":\"${habilitado.value}\", \"clave\":\"${clave.value}\", \"contacto\":\"${contacto.value}\",\"copia\":\"${copia.value}\", \"host\":\"${host.value}\", \"limite_de_tiempo\":\"${limiteTiempo.value}\", \"limite_de_cantidad\":\"${limiteCantidad.value}\", \"puerto\":\"${puerto.value}\"}`
            config.value = `{\"finMes\":\"${habilitadoMes.value}\",\"finAnuncio\":\"${habilitadoAnuncio.value}\"}`
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            client.rawRequest(/* GraphQL */ `
            mutation($id: ID!, $tipo:String!, $config:String!){
               modificaConfigsistema(id: $id, input: {
                        tipo: $tipo,
                        config: $config,

                    }){
                        id,
                        tipo,
                        config

                    }
            }`,
            {
                id: id.value,
                tipo: tipo.value,
                config: config.value,
            },
            {

                authorization: `Bearer ${ localStorage.getItem('user_token') }`

            })
            .then((data) => {
                // console.log(data)
                router.push({name: 'Configuraciones'})
                let accion = "edicionintegracion"
                store.commit('verificar_carga',accion)

            }).catch(error => {
            //   console.log(error.response);

            })
        }


        const volver = () => {
            router.go(-1)
        }

        return {
            isMobile ,
            error,
            message_error ,
            clave ,
            host ,
            puerto ,
            limiteCantidad ,
            limiteTiempo ,
            copia ,
            contacto ,
            habilitado,
            tipo,
            config ,
            editar,
            id,
            habilitarTerminarAnuncio,
            habilitarTerminarMes,
            volver,
            validar,
            traerCorreo ,
            editarConfiguracionCorreo,
            crearIntegracionCorreo,
            passwordFieldType,
            cargando,
            habilitadoMes,
            habilitadoAnuncio

        }

},



    created () {
        this.traerCorreo()
    }

}
</script>

<style scoped>


.checkbox-label input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label  .checkbox-custom{

        position: absolute;
        top:0px;
        left: -45px;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border-radius:5px;
        border: 2px solid grey;

}
@media screen and (max-width: 770px) {
    .checkbox-label  .checkbox-custom{

        left: auto;

    }
}

.checkbox-label .checkbox-custom::after{

        position: absolute;
        content: "";
        top:12px;
        left: 12px;
        height: 0px;
        width: 0px;
        border-radius:5px;
        border:  solid rgb(47, 133, 214);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(0deg) scale(0);
        -ms-transform: rotate(0deg) scale(0);
        transform: rotate(0deg) scale(0);
        opacity: 1;
}
.checkbox-label input:checked ~ .checkbox-custom{
        background-color: white;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
         transform: rotate(0deg) scale(1);
         opacity:1;

         border: solid #008575;
         border-radius: 5px;


}
.checkbox-label input:checked ~ .checkbox-custom::after{

        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
         transform: rotate(45deg) scale(1);
         opacity:1;
         left:8px;
         top: 3px;
         width: 6px;
         height: 12px;
         border: solid #008575;
         border-width: 0 2px 2px 0;
         background-color: transparent;
         border-radius: 0;


}
.input2{
      width: 14.6rem;
}
.input{

  width: 17.1rem;

}
.options{
        width: 17.1rem;
}
.select{
    width: 17.1rem;
}
.textarea{

    width: 17.1rem;
}

</style>